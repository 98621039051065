import { useEffect } from 'react'

// Redirect to careers.yapstone.com, mimicking old redirect from yapstone.com/careers
const CareersPage = () => {
  useEffect(() => {
    window.location.replace('https://careers.yapstone.com')
  })

  return null
}

export default CareersPage
